import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Typography, Grid, Button, Container, Box, Hidden } from "@mui/material";
import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";
import { Event } from "../Tracking";
import UnitGallery from "../UnitGallery";
import FloorPlanTabsAppBar from "./FloorPlanTabsAppBar";
import RenderMarkdown from "../RenderMarkdown";

import ImageContainer from "../ImageContainer";
import ZoomedFloorplan from "../ZoomedFloorplan";

function FloorplanTabsMadison({
  isTouch,
  data,
  convertColor,
  specs,
  getVrLinkCallback,
  setUnitName,
  setValue,
  value,
  setCurrentUnit,
  UnitLocation,
  handleUnitLocationChange,
  ...props
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValue(0);
  }, []);

  const handleChange = (event, newValue) => {
    Event("click", "Change Floorplan", event.currentTarget.dataset.unit);
    setCurrentUnit(() => Number(event.currentTarget.dataset.unitid || 0));
    setValue(() => newValue);
    setUnitName(event.currentTarget.dataset.unit);
  };

  const handleOnClick = event => {
    setOpen(true);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`floorplan-tabpanel-${index}`}
        aria-labelledby={`floorplan-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  return (
    data?.length > 0 && (
      <Wrapper maxWidth={false} disableGutters className="floorplans">
        <FloorPlanTabsAppBar
          isTouch={isTouch}
          data={data}
          handleChange={handleChange}
          value={value}
          UnitLocation={UnitLocation}
          handleUnitLocationChange={handleUnitLocationChange}
        />

        {data.map((unit, index) => {
          const { Bedrooms, Bath, Notes, Name } = unit.attributes;
          const PDF = unit?.attributes?.PDF?.data?.attributes || "";
          const { data: Plans } = unit?.attributes.Plans || [];
          const unit_gallery = unit?.attributes?.unit_gallery?.data?.id;
          // getUnitGalleryArray(unit?.unit_gallery);
          // TODO use data[0].Name instead of Bedrooms to set this value
          const matterport = getVrLinkCallback(unit?.Name);
          return (
            <TabPanel key={`${Name}${index}`} value={value} name={Name} index={index}>
              <ContentWrapper
                container
                justifyContent="space-evenly"
                alignContent="flex-start"
                alignItems="flex-start"
                maxWidth={"lg"}
                component={Container}
              >
                <Grid item xs={12} md={5} lg={6} align="center">
                  <ImageContainer
                    id="floorplan-image"
                    srcimage={""}
                    alttext={""}
                    src={Plans[0]?.attributes.url}
                    onClick={handleOnClick}
                    maxHeight={isTouch ? 480 : null}
                    isTouch={isTouch}
                  />
                  {open && (
                    <ZoomedFloorplan
                      showLabel={true}
                      open={open}
                      setOpen={setOpen}
                      image={Plans[2]?.attributes.url || Plans[1]?.attributes.url}
                      imageAlt={`${Bedrooms} / ${Bath}`}
                      useBackground={true}
                    />
                  )}
                </Grid>

                {unit_gallery && (
                  <Hidden mdUp>
                    <Grid item sm={12}>
                      <UnitGallery isTouch={isTouch} gallery={unit_gallery} />
                    </Grid>
                  </Hidden>
                )}

                <Grid item xs={12} md={4} lg={5}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="body1"
                      style={{
                        textTransform: "uppercase",
                        fontSize: "1.5rem",
                        fontWeight: "bolder",
                        fontStyle: "italic",
                      }}
                    >
                      {Bedrooms} / {Bath}
                    </Typography>

                    <Grid item xs={12} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      {PDF && (
                        <Button variant="contained" target="Oliver" href={PDF.url} tabIndex={0}>
                          Download PDF
                        </Button>
                      )}

                      {matterport && (
                        <Button
                          type="text"
                          variant="contained"
                          target="Oliver"
                          startIcon={<ThreeDRotationIcon fontSize="large" />}
                          href={`https://my.matterport.com/show/?m=${matterport}&brand=0&qs=1&nt=1&play=1&search=0`}
                          style={{
                            marginLeft: "2rem",
                            backgroundColor: "orange",
                          }}
                          onClick={e => Event("click", "View 3d", matterport)}
                          tabIndex={0}
                        >
                          "View 3d tour"
                        </Button>
                      )}
                    </Grid>
                    <Typography
                      variant="body1"
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      {Notes}
                    </Typography>
                    <RenderMarkdown body={specs} style={{ paddingLeft: 0 }} listColumns={1} />

                    <Grid
                      container
                      // uncomment this when book a tour active
                      // justifyContent="space-evenly"
                      alignContent="center"
                      style={{ maxWidth: 500 }}
                    >
                      {/* <Button
                          type="text"
                          onClick={handleCalendlyClick}
                          variant="contained"
                          role="presentation"
                          startIcon={<ScheduleIcon />}
                        >
                          Book a Tour
                        </Button> */}
                      {/* {unit?.unit_gallery && (
                        <Button
                          style={{ marginLeft: "2rem" }}
                          type="text"
                          variant="contained"
                          role="presentation"
                        >
                          View Gallery
                        </Button>
                      )} */}
                    </Grid>
                  </Grid>
                </Grid>

                {unit_gallery && (
                  <Hidden smDown>
                    <Grid item sm={12}>
                      <UnitGallery gallery={unit_gallery} />
                    </Grid>
                  </Hidden>
                )}
              </ContentWrapper>
            </TabPanel>
          );
        })}
      </Wrapper>
    )
  );
}

const Wrapper = styled(Container)`
  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 2.0588235294117645rem;
  }
`;
const ContentWrapper = styled(Grid)`
  /* padding-top: 1rem; */
  padding-bottom: 3rem;
  padding-top: 1rem;
  ${props => props.theme.breakpoints.up("md")} {
    padding-bottom: 0rem;
    padding-top: 4rem;
  }
`;
FloorplanTabsMadison.propTypes = {};

export default FloorplanTabsMadison;
