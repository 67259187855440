import axios from "axios";
const qs = require("qs");

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

/**
 * Generic function that builds a get request and sets the state in the
 * calling file using the supplied setter function.
 *
 *
 * @export
 * @param {*} setter
 * @param {*} path
 * @param {*} dataObject optional
 */
export async function getData(setter, path, dataObject) {
  //"http://[API_ENDPOINT]:1337/api/home/?populate[Hero][populate]=%2A&populate[Intro][populate]=%2A"

  // NOTE the query string is used by newer versions of strapi ^4.3.2 for sure
  // .get(`${process.env.REACT_APP_CMS_URL}/api/home/?${query}`)
  const query = qs.stringify(
    {
      populate: "deep",
    },
    {
      encodeValuesOnly: true,
    }
  );

  const config = {
    headers: {
      Authorization: "Bearer " + process.env.REACT_APP_STRAPI_ACCESS_TOKEN,
    },
  };
  axios
    // to use the newer strapi version use this
    // .get(`${process.env.REACT_APP_CMS_URL}/api${path}/?${query}`)
    .get(`${process.env.REACT_APP_CMS_URL}${path}?${query}`, config)
    .then(res => {
      if (dataObject) {
        setter(res.data[dataObject]);
      }

      if (!dataObject) {
        setter(res.data);
      }

      return res.data;
    })
    .catch(function (error) {
      console.log(`Error fetching ${path} data from CMS`, error);
    });
}
