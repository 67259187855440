import React from "react";
import styled from "@emotion/styled";
import { Typography, Container, Grid } from "@mui/material";
import ReactMarkdown from "react-markdown";

function Hero(props) {
  const renderTypography = props => {
    return (
      <Typography variant={props.node.tagName === "p" ? "body1" : props.node.tagName}>
        {props.children.map((child, index) => child)}
      </Typography>
    );
  };

  return (
    <HeroDiv style={props.style} image={props?.data?.heroImage}>
      <Grid component={Container} container maxWidth="lg">
        <Grid item xs={12} className="hero-title">
          <LogoWrapper
            src={props?.MadisonLogo}
            alt="Madison Avenue Executive Suites Logo"
            role="presentation"
          />
          <ReactMarkdown
            components={{
              p: renderTypography,
              h1: renderTypography,
              h2: renderTypography,
              h3: renderTypography,
              h4: renderTypography,
              h5: renderTypography,
              h6: renderTypography,
            }}
          >
            {props.data.heroTitle}
          </ReactMarkdown>
        </Grid>
      </Grid>
    </HeroDiv>
  );
}
const LogoWrapper = styled("img")`
  width: 284px;
  height: auto;
  padding-top: 0;
  margin-bottom: 4rem;
  ${props => props.theme.breakpoints.up("sm")} {
    padding-top: 3rem;
  }
  ${props => props.theme.breakpoints.up("md")} {
    padding-top: 5rem;
  }
`;
const HeroDiv = styled("div")`
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: url(${props => (props?.image?.url ? props?.image?.url : "")}) center left -277px / cover
    no-repeat;

  height: 783px;
  min-height: 330px;

  width: auto;

  ${props => props.theme.breakpoints.up("sm")} {
    align-items: center;
    background: url(${props => (props?.image?.url ? props?.image?.url : "")}) right/ auto 100%
      no-repeat;
    min-height: ${props => props?.image?.height / 3}px;
    max-height: ${props => props?.image?.height * 0.5}px;
  }
  ${props => props.theme.breakpoints.up("md")} {
    align-items: center;
    background: url(${props => (props?.image?.url ? props?.image?.url : "")}) center/cover no-repeat;
    min-height: ${props => props?.image?.height / 3}px;
    max-height: ${props => props?.image?.height}px;
  }
  .hero-title {
    padding-bottom: 60px;
    ${props => props.theme.breakpoints.up("sm")} {
      padding-bottom: initial;
    }
    ${props => props.theme.breakpoints.up("md")} {
      padding-bottom: initial;
    }
    ${props => props.theme.breakpoints.up("lg")} {
      padding-bottom: initial;
    }
    h1 {
      text-align: center;
      color: ${props => props.theme.palette.common.white};
      font-size: 3.125rem;

      ${props => props.theme.breakpoints.up("sm")} {
        padding-bottom: initial;
        font-size: 2.6rem;
      }
      ${props => props.theme.breakpoints.up("md")} {
        padding-bottom: initial;
        font-size: 3.2rem;
      }
      ${props => props.theme.breakpoints.up("lg")} {
        margin-top: 6rem;
        padding-bottom: initial;
        font-size: 4rem;
      }
    }
  }
  > h4 {
    text-transform: initial;
  }

  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    text-align: center;
  }
`;

export default Hero;
