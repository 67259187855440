import React from "react";
import { Typography, Grid, AppBar, Tabs, Tab } from "@mui/material";
import styled from "@emotion/styled";
function FloorPlanTabsAppBar(
  { isTouch, data, value, handleChange, handleUnitLocationChange, UnitLocation },
  ...props
) {
  return (
    <>
      <TabsWrapperBuilding
        value={UnitLocation}
        onChange={handleUnitLocationChange}
        aria-label="Floorplans Tab"
        variant={"fullWidth"}
        centered={isTouch}
        // selectionFollowsFocus
      >
        {/* NOTE: The set the order of the Building/Penthouse by changing the order of the labels.
    Change the UnitLocation in src/components/pages/Floorplans.js to correspond to the 
    new label index. 
    You will also need to change the logic:{UnitLocation === 1 ? Bedrooms : Name} to reference the correct index of East to show the Bedrooms rather than the unit name.*/}
        {[{ Label: "West Tower" }, { Label: "East Tower" }].map((unitType, index) => {
          // console.log(index + 10);
          const tabLabel = (
            <Typography variant="body1" style={{ fontWeight: 800, whiteSpace: "nowrap" }}>
              {unitType?.Label}
            </Typography>
          );
          return (
            <TabWrapper
              key={`unit-location-tab-${index}`}
              component={Grid}
              item
              xs={10}
              sm={2}
              // data-unit={unit?.Label}
              label={tabLabel}
              // onClick={handleOnClick}
              id={`unit-location-tab-${index}`}
              aria-controls={`unit-location-tabpanel-${index}`}
              // tabIndex={index + 10}
            />
          );
        })}
      </TabsWrapperBuilding>
      <AppBar
        // component={Grid}
        // container
        // justifyContent="space-evenly"
        position="static"
        style={{ width: "100%" }}
        color="secondary"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Floorplans Tab"
          variant={isTouch ? "scrollable" : "fullWidth"}
          allowScrollButtonsMobile
          selectionFollowsFocus
          scrollButtons={true}
          centered={isTouch}
        >
          {/* <Tab label="Item One" {...a11yProps(0)} /> */}
          {data.map((unit, index) => {
            const { Name, id } = unit.attributes;
            const tabLabel = (
              <div>
                <Typography variant="body1">{Name}</Typography>
              </div>
            );
            return (
              <Tab
                component={Grid}
                item
                style={{ borderRight: "2px solid white" }}
                xs={10}
                sm={2}
                key={`floorplan-tab-${index}`}
                data-unit={Name}
                data-unitId={id}
                label={tabLabel}
                // onClick={handleOnClick}
                id={`floorplan-tab-${index}`}
                aria-controls={`floorplan-tabpanel-${index}`}
                tabIndex={0}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </>
  );
}
const TabsWrapperBuilding = styled(Tabs)`
  background-color: ${({ theme }) => theme.palette.primary.slate};
  // .MuiTabs-indicator {
  //   background-color: ${({ theme }) => theme.palette.primary.yellow};
  // }
`;
const TabWrapper = styled(Tab)`
  border-right: 2px solid white;
  &#unit-location-tab-0 {
    // background: blue;
  }

  &#unit-location-tab-1 {
    // background: red;
  }
  &#unit-location-tab-2 {
    // background: green;
  }
`;
FloorPlanTabsAppBar.propTypes = {};

export default FloorPlanTabsAppBar;
