import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Event } from "./Tracking";
import ImageGallery from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ZoomedImage from "./ZoomedImage";

export default function Gallery({ isTouch, options, data, ...props }) {
  const [open, setOpen] = useState(false);
  const [zoomedImage, setZoomedImage] = useState({ url: "", width: 0 });
  const [altImage, setAltImage] = useState();

  const [images, setImages] = useState([]);
  const handleDragStart = e => e.preventDefault();

  useEffect(() => {
    const imagesTemp = data?.map((image, index) => {
      let largeimage = image?.attributes?.formats?.xlarge || image?.attributes?.formats?.large;
      let srcImage = image?.attributes?.formats?.medium || image?.attributes?.formats?.small;
      let imgAlt =
        image?.attributes?.alternativeText === ""
          ? image?.attributes?.name
          : image?.attributes?.alternativeText;

      return (
        <ImageContainer
          // src={image?.attributes?.formats?.large?.url}
          src={srcImage?.url}
          onDragStart={handleDragStart}
          onClick={e => handleOnClick(largeimage, index, e)}
          alt={imgAlt}
          loading="lazy"
          role="presentation"
          width={srcImage?.width}
          height={srcImage?.height}
          nogallery={data?.length === 1}
        />
      );
    });

    setImages(imagesTemp);
  }, [data]);

  const handleOnClick = (largeimage, index, e) => {
    e.preventDefault();
    setZoomedImage({ url: largeimage?.url, width: largeimage?.width });
    // setImage(e.currentTarget.attributes.largeimage.value);
    setAltImage(
      largeimage?.alternativeText !== "" ? largeimage?.alternativeText : largeimage?.name
    );
    Event("click", "Unit Gallery", e?.currentTarget?.attributes?.alt?.value);

    setOpen(true);
  };

  return (
    <>
      <GalleryContainer maxWidth="lg" id="GalleryContainer" offset={props?.offset} disableGutters>
        {props.children}
        {images?.length > 1 && <ImageGallery {...options} items={images} paddingLeft={0} />}
        {images?.length === 1 && images[0]}
      </GalleryContainer>

      {open && (
        <ZoomedImage
          open={open}
          setOpen={setOpen}
          image={zoomedImage.url}
          width={zoomedImage.width}
          imageAlt={altImage}
          keepMounted
        />
      )}
    </>
  );
}

const ImageContainer = styled("img")`
  background: top left/100% no-repeat url(${props => props.src});
  width: ${props => (props.nogallery ? "100%" : "666px")};
  height: ${props => (props.nogallery ? "auto" : "333px")};
  &:hover {
    cursor: zoom-in;
  }
  ${props => props.theme.breakpoints.only("xs")} {
    width: 370px;
    height: 160px;
    margin-right: 6px;
  }
  /* padding: 1rem; */
`;

const GalleryContainer = styled(Container)`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin-top: ${props => (props?.offset ? "5rem" : "0")};
  padding: 0;
  /* ${props => props.theme.breakpoints.up("sm")} {
      margin-left: 0.5rem;
    } */
  ${props => props.theme.breakpoints.up("md")} {
    /* padding: 0 1.5rem; */
  }

  > .inner {
    ${props => props.theme.breakpoints.up("sm")} {
    }
  }
  .alice-carousel__next-btn-item,
  .alice-carousel__prev-btn-item {
    ${props => props.theme.breakpoints.only("xs")} {
      display: none;
    }
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90.5%;
    width: 100px;
    font-size: 75px;
    font-weight: bold;
    color: rgb(255, 255, 255, 0.2);
    background: transparent;
    border: none;
    position: absolute;
    z-index: 100;
    &:hover {
      /* color: red; */
      color: rgb(255, 255, 255, 0.8);
      background: rgb(255, 255, 255, 0.1);
    }
    span {
      width: fit-content;
    }
  }
  .alice-carousel__prev-btn-item {
    left: 0;
  }
  .alice-carousel__next-btn-item {
    right: ${props => (props?.offset ? "-105px" : "0")};
    ${props => props.theme.breakpoints.only("xs")} {
      right: -105px;
    }
    ${props => props.theme.breakpoints.only("sm")} {
      right: 49px;
    }
  }
  li.alice-carousel__stage-item :not(.__cloned) {
    width: auto !important;
    margin: 0 6px 0 0;
  }
  .alice-carousel__stage {
    max-width: 1280px;
  }

  .alice-carousel__stage-item {
    margin: 0 6px 0 0;
  }

  .alice-carousel__wrapper {
    width: ${props => (props?.offset ? "100vw" : "auto")};
  }
  .alice-carousel__dots {
    position: absolute;
    /* width: 100%; */
    right: 37%;
    margin: -40px 0 0 0;
    padding: 0;
    ${props => props.theme.breakpoints.up("sm")} {
      right: 59%;
    }
    ${props => props.theme.breakpoints.up("md")} {
      right: 63%;
    }
    ${props => props.theme.breakpoints.up("lg")} {
      right: 71%;
    }
  }
  .alice-carousel__dots-item.__active {
    background-color: ${props => props.theme.palette.primary.navy};
    border: 1px solid ${props => props.theme.palette.common.white};
  }
  .alice-carousel__dots-item {
    width: 16px;
    height: 16px;

    border: 1px solid ${props => props.theme.palette.primary.navy};
  }
  .alice-carousel {
    width: ${props => (props?.offset ? "90%" : "100%")};
    ${props => props.theme.breakpoints.only("xs")} {
      width: calc(100dvw - 1.75rem);
      margin: 0;
    }
    ${props => props.theme.breakpoints.only("sm")} {
      width: 100vw;
      margin: 0;
    }

    ${props => props.theme.breakpoints.up("md")} {
    }
  }
`;
