import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";

import RenderMarkdown from "./RenderMarkdown";
import Button from "./Button";
//TODO This is pretty clumsy IRL. Especially the use of useOverlay.
function MarkdownSection({ options = {}, ...props }) {
  const { cols, imagePositionRight, title, body, listColumns, buttonText, buttonLink } = options;

  return (
    <SectionContainer
      //TODO Change this to an option object options = {textColor:props.textColor, ...etc}
      component="section"
      disableGutters
      maxWidth={false}
      options={options}
      {...props}
    >
      <Container
        component={Grid}
        container
        direction={imagePositionRight ? "row-reverse" : "row"}
        justifyContent="space-evenly"
        alignItems="center"
        className="inner"
        maxWidth="lg"
      >
        {props.children}

        <CopyContainer item xs={cols ? cols : 12}>
          <RenderMarkdown body={title} listColumns={listColumns} />
          <RenderMarkdown body={body} listColumns={listColumns} />

          {buttonText && (
            <Button href={`${process.env.PUBLIC_URL}${buttonLink}`}>{buttonText}</Button>
          )}
        </CopyContainer>
      </Container>
    </SectionContainer>
  );
}
const CopyContainer = styled(Grid)`
  //This is the copy/text portion section
  //markdown
  width: 100%;
  /* text-align: center; */
  text-align: left;
  ${props => props.theme.breakpoints.up("sm")} {
    text-align: left;
    align-items: flex-start;
    max-width: 812px;
    /* width: 45%; */
    padding: 0;
  }
  & > p {
    margin: 1rem auto;
    /* text-align: center; */

    color: ${({ options }) => options?.textColor};
    ${props => props.theme.breakpoints.up("sm")} {
      /* max-width: 90%; */
      text-align: left;
    }
    ${props => props.theme.breakpoints.up("md")} {
      max-width: 100%;
    }
  }
  & > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ options }) => options?.textColor}; //NOTE anticipating this for reverse text
  }

  & > h3 {
    ${props => props.theme.breakpoints.only("xs")} {
      margin-top: 2rem;
    }
  }
  ol {
    list-style: none;
    counter-reset: item;

    li {
      counter-increment: item;
      margin-bottom: 0.7rem;
    }
    li:before {
      margin-right: 10px;
      content: counter(item);
      background: rgb(155, 211, 214);
      border-radius: 100%;
      color: white;
      width: 1.2rem;
      text-align: center;
      display: inline-block;
    }
  }
  ul {
    display: inline-block;
    width: 100%;
    list-style-position: inside;
    list-style: ${({ options }) => {
      return options?.bullet !== undefined ? "none" : "disc";
    }};
    padding: 0;
    ${props => props.theme.breakpoints.only("xs")} {
      /* list-style: disc; */
      padding: 0 5%;
    }
    font-size: 1rem;
    ${props => props.theme.breakpoints.up("sm")} {
      /* list-style: disc; */
      padding-left: 1rem;
    }
    &:last-child {
      margin-bottom: 0;
      ${props => props.theme.breakpoints.up("sm")} {
        /* list-style: disc; */
        /* margin-bottom: 2rem; */
      }
    }
    li::marker {
      color: ${({ options }) => options?.bulletcolor};
    }
    li {
      color: ${({ options }) => options?.textColor};
      margin: 0 1rem 0 0 !important;
      display: list-item;
      margin-bottom: 0.25rem;
      break-inside: avoid-column;
      /* TODO Handle no bullet object better */
      &:before {
        /* content: url(${({ options }) => options?.bullet?.file}); */
        display: inline-block;
        position: absolute;
        /* margin-left: ${({ options }) => options?.bullet?.spacing}; */
        margin-top: 3.2px;
      }
      & > a {
        color: ${props => props.theme.palette.primary.main};
        text-decoration: none;
        &:hover {
          color: ${props => props.theme.palette.primary.dark};
        }
        &:visited {
          color: ${props => props.theme.palette.primary.dark};
        }
        &:active {
          color: ${props => props.theme.palette.primary.dark};
        }
      }
    }
  }
`;

const SectionContainer = styled(Container)`
  display: flex;
  flex-flow: wrap;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;

  ${({ $useoverlay }) =>
    $useoverlay === "true" ? "box-shadow: inset 0 0 0 800px rgba(43, 44, 66, 0.96);" : null}
  background: url(${({ options }) =>
    options?.backgroundImage?.url ? options?.backgroundImage?.url : ""}) center/cover no-repeat;
  background-color: ${({ $color }) => $color};
  padding-top: 0;
  padding-bottom: 4rem;

  ${props => props.theme.breakpoints.up("md")} {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  h1 {
    ${props => props.theme.breakpoints.only("xs")} {
      margin-top: 3rem;
    }
    ${props => props.theme.breakpoints.up("sm")} {
      margin-top: 3rem;
    }
  }

  h6 {
    color: rgba(70, 101, 102, 1);
  }
  img {
    display: inline-block;
    width: 100vw;
    height: auto;
    ${props => props.theme.breakpoints.up("sm")} {
      /* max-width: 500px; */
      width: 100%;
    }
  }
`;
MarkdownSection.propTypes = {
  options: PropTypes.object,
};
MarkdownSection.defaultProps = {
  options: {},
};

export default MarkdownSection;
