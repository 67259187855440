import React, { useState, useEffect } from "react";

import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Event } from "./Tracking";

import FloorplanTabsMadison from "./FloorPlanTabsMadison/FloorPlanTabsMadison";

function FloorplansMadison({ PageView, convertColor, isSmall, isXsmall, isTouch, ...props }) {
  // const [isTouch, setIsTouch] = useState(false);
  const [unitName, setUnitName] = useState("Veneta Suite");
  const [value, setValue] = useState(0); //the unit id
  const [currentUnit, setCurrentUnit] = useState(7);
  const [UnitLocation, setUnitLocation] = useState(1);
  const [WestUnits, setWestUnits] = useState([]);
  const [EastUnits, setEastUnits] = useState([]);

  const regex = new RegExp("- Expertly designed and furnished by Paul Lavoie\n", "g");
  const revisedSpecs = props?.data?.data?.attributes?.Specifications?.replace(
    regex,
    "- Expertly designed by Paul Lavoie and furnished by Amanda Hamilton\n"
  );

  useEffect(() => {
    // const WestUnits = data.units.filter(
    //   (unit, in

    if (props?.data?.data?.attributes?.units) {
      setWestUnits(
        props?.data?.data?.attributes?.units?.data?.filter((unit, index) => {
          return unit.attributes.West === true;
        })
      );
    }

    // const EastUnits = data.units.filter(
    //   (unit, index) => unit.attributes.Penthouse !== true && unit.West !== true
    // );
    if (props?.data?.data?.attributes?.units) {
      setEastUnits(
        props?.data?.data?.attributes?.units?.data?.filter((unit, index) => {
          return unit.attributes.West !== true;
        })
      );
    }
  }, [props?.data?.data?.attributes?.units]);

  const handleUnitLocationChange = (event, newValue) => {
    Event("click", "Change Location", event.currentTarget.dataset.unit);
    setUnitLocation(newValue);
  };

  //TODO this should be sortable at the CMS
  let reorderedUnits = [];

  if (UnitLocation === 0) {
    reorderedUnits = WestUnits?.sort(function (a, b) {
      return a.id - b.id || a.name.localeCompare(b.name);
    });
  }
  if (UnitLocation === 1) {
    reorderedUnits = EastUnits?.sort(function (a, b) {
      return a.id - b.id || a.name.localeCompare(b.name);
    });
  }

  /**
   * filters the reorderedUnits array and returns the id of the unit with the corresponding Name
   *
   * @param {string} unitName
   * @return {integer}
   */
  const filterUnit = unitName => {
    return reorderedUnits.filter(unit => {
      return unitName === unit.Name;
    })[0]?.id;
  };

  /**
   * Converts the id associated with the unit in the CMS into a local id to target
   * the correct unit when switching units using the interactive floorplan
   *
   * @param {integer} id
   * @return {integer}
   */
  const getReorderedUnitId = id => {
    return reorderedUnits
      .map((unit, index) => {
        if (unit.id === id) {
          return index;
        }
      })
      .filter(unit => unit >= 0)[0];
  };

  // const reorderedUnits =[
  //   // manually sort the units, in this case make the York sequential
  //   props.data?.units[0],
  //   props.data?.units[1],
  //   props.data?.units[2],
  //   props.data?.units[4],
  //   props.data?.units[5],
  //   props.data?.units[3],
  //   props.data?.units[7],
  //   props.data?.units[6],
  // ];

  useEffect(() => {
    PageView();
  }, [PageView]);

  const getMatterPortId = name => {
    let matterport = null;
    // if (name === "York II") {
    //   matterport = "ehRij5KyaQk";
    // }

    return matterport;
  };

  return (
    <Wrapper maxWidth={false} disableGutters>
      <div id="scrolltarget" style={{ position: "relative", bottom: 100 }} />
      <Container maxWidth={false} disableGutters>
        <section id="floorplans">
          {UnitLocation === 0 && (
            <FloorplanTabsMadison
              isTouch={isTouch}
              convertColor={props.convertColor}
              data={reorderedUnits}
              specs={props?.data?.data?.attributes?.Specifications}
              specs={UnitLocation ? props?.data?.data?.attributes?.Specifications : revisedSpecs}
              getVrLinkCallback={getMatterPortId}
              setUnitName={setUnitName}
              value={value}
              setValue={setValue}
              theme={props?.theme}
              setCurrentUnit={setCurrentUnit}
              UnitLocation={UnitLocation}
              handleUnitLocationChange={handleUnitLocationChange}
            />
          )}
          {UnitLocation === 1 && (
            <FloorplanTabsMadison
              isTouch={isTouch}
              convertColor={props.convertColor}
              data={reorderedUnits}
              specs={props?.data?.data?.attributes?.Specifications}
              getVrLinkCallback={getMatterPortId}
              setUnitName={setUnitName}
              value={value}
              setValue={setValue}
              theme={props?.theme}
              setCurrentUnit={setCurrentUnit}
              UnitLocation={UnitLocation}
              handleUnitLocationChange={handleUnitLocationChange}
            />
          )}
        </section>
      </Container>
      {/* <MarkdownSection
        options={{
          parent: "FloorplansMadison",
          title: props?.data?.data?.attributes?.Distinctive[0]?.Title,
          body: props?.data?.data?.attributes?.Distinctive[0]?.Copy,
          color: convertColor(props?.data?.data?.attributes?.Distinctive[0]?.Color),
          imagePositionRight:
            props?.data?.data?.attributes?.Distinctive[0]?.ImagePosition === "Right",
          style: { backgroundColor: "#f5f5f5" },
          cols: isSmall || isXsmall ? 12 : 8,
        }}
      /> */}
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  .floorplans {
    background: linear-gradient(180deg, rgba(184, 179, 170, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
  svg {
    overflow: visible;
  }
`;

export default FloorplansMadison;
