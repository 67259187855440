import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  Container,
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  Hidden,
  CircularProgress,
  Alert as MuiAlert,
} from "@mui/material";

import MailChimpForm from "./MailChimpForm";
import { Event } from "./Tracking";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink } from "react-router-dom";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

function Register({ open, setOpen, ...props }) {
  const [messageSent, setMessageSent] = React.useState(false);

  return (
    <RegisterContainer align="center" open={open} disableGutters maxWidth={"lg"} id="register">
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
        maxWidth={800}
        p={2.5}
        style={{ maxWidth: 800, padding: "1.125rem" }}
      >
        {!open && !messageSent && (
          <>
            <Typography variant="h1" paragraph>
              Stay in the <strong>know</strong>
            </Typography>
            <Typography variant="body1">
              Sign up for our newsletter and get exclusive content, promotions, and more!
            </Typography>
          </>
        )}
        {messageSent && (
          <>
            <Typography align="center" style={{ marginBottom: 0 }} variant="h1">
              Awesome!
            </Typography>
            <Typography align="center" variant="h2">
              We'll be in touch.
            </Typography>
            <Typography align="center" variant="body1">
              Check your email for a confirmation link from us.
              <br />
              You'll need to confirm to complete the process.
            </Typography>
          </>
        )}
        {!messageSent && (
          <MailChimpForm open={open} setOpen={setOpen} setMessageSent={setMessageSent} />
        )}
      </Grid>
    </RegisterContainer>
  );
}

const RegisterContainer = styled(Container)`
  background-color: ${props => props.theme.palette.primary.taupe};
  padding: 4rem 1rem 0;
  margin-bottom: 4rem;
  & > div > img {
    position: relative;
    top: -126px;
    margin-bottom: -180px;
    z-index: 0;
    ${props => props.theme.breakpoints.up("md")} {
      top: -109px;
      margin-bottom: -240px;
      padding: 4rem 4rem 0;
    }
  }
  & > div {
    min-height: 300px;
  }

  h1 {
    margin-top: ${props => (props.open ? `9rem` : `2rem`)};
    margin-bottom: ${props => (props.open ? `9rem` : `2rem`)};
  }
  h1,
  h2,
  p {
    z-index: 1;
  }
`;
Register.propTypes = {};

export default Register;
