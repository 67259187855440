import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import GalleryComponent from "./GalleryComponent";
import ImageContainer from "./ImageContainer";
const qs = require("qs");
function UnitGallery({
  isTouch,
  floorplan,
  matterport,
  unit,
  showClose = true,
  gallery,
  ...props
}) {
  const [unitGallery, setUnitGallery] = useState([]);
  const [galleryDataReady, setGalleryDataReady] = useState(false);

  useEffect(() => {
    const cachedGalleryData = localStorage.getItem(`unitGallery_${gallery}`);
    const cachedTimestamp = localStorage.getItem(`unitGallery_${gallery}_timestamp`);
    if (cachedGalleryData && cachedTimestamp) {
      const parsedTimestamp = parseInt(cachedTimestamp, 10);
      const currentTime = new Date().getTime();
      const oneDayInMillis = 24 * 60 * 60 * 1000;

      if (currentTime - parsedTimestamp < oneDayInMillis) {
        setUnitGallery(JSON.parse(cachedGalleryData));
        setGalleryDataReady(true);
      } else {
        localStorage.removeItem(`unitGallery_${gallery}`);
        localStorage.removeItem(`unitGallery_${gallery}_timestamp`);
        getUnitGalleryArray(gallery);
      }
    } else {
      getUnitGalleryArray(gallery);
    }
  }, [gallery]);

  const getUnitGalleryArray = id => {
    const query = qs.stringify(
      {
        populate: "deep",
      },
      {
        encodeValuesOnly: true,
      }
    );
    // debugger;
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/unit-galleries/${id}?${query}`)
      .then(res => {
        const unitImages = res?.data?.data?.attributes?.UnitImages?.data || [];

        setUnitGallery(unitImages);
        setGalleryDataReady(true);
        // localStorage.setItem(`unitGallery_${id}`, JSON.stringify(unitImages));
        // localStorage.setItem(`unitGallery_${id}_timestamp`, new Date().getTime().toString());
      })
      .catch(function (error) {
        if (floorplan) {
          setUnitGallery([]);
          setGalleryDataReady(true);
          return true;
        }
        setGalleryDataReady(false);
        //TODO return an error image
        console.log(`Error fetching Unit ${id} Gallery data from CMS`, error);
      });
  };

  return (
    <Wrapper component="section" disableGutters id="unitGallery">
      <Container
        component={Grid}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
        className="inner"
        id="inner"
        maxWidth="lg"
        disableGutters={true}
      >
        {/* {galleryDataReady && ( */}
        {galleryDataReady && (
          <GalleryComponent
            isTouch={isTouch}
            unit={unit}
            imagecomponent={ImageContainer}
            data={unitGallery}
            options={{
              infinite: true,
              autoPlay: true,
              autoPlayInterval: 1500,
              animationDuration: 1000,
              autoPlayStrategy: "all",
              autoHeight: false,
              autoWidth: true,
              mouseTracking: false,
              showThumbnails: false,
              disableDotsControls: true,
              disableButtonsControls: false,
              // onSlideChanged: syncMainAfterChange,
            }}
          />
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  overflow: hidden;
  margin-top: 2rem;
`;

UnitGallery.propTypes = {};

export default UnitGallery;
