export const defaultNavData = [
  {
    id: 2,
    Label: "",
    link: "",
    NavItemAltTag: "",
    NavItemIcon: null,
  },
];
export const defaultFleetwoodData = {
  id: 1,
  created_at: "2021-10-14T05:43:49.524Z",
  updated_at: "2021-10-25T18:02:00.500Z",
  Hero: {
    id: 6,
    HeroTitle: "...",
    HeroButton: null,
    HeroImage: [
      {
        id: 95,
        name: "FleetwoodHero.png",
        alternativeText: "",
        caption: "",
        width: 583,
        height: 443,
        formats: {
          small: {
            ext: ".png",
            url: "",
            hash: "small_Fleetwood_Hero_b62fd70389",
            mime: "image/png",
            name: "small_FleetwoodHero.png",
            path: null,
            size: 430.08,
            width: 500,
            height: 380,
          },
          thumbnail: {
            ext: ".png",
            url: "",
            hash: "thumbnail_Fleetwood_Hero_b62fd70389",
            mime: "image/png",
            name: "thumbnail_FleetwoodHero.png",
            path: null,
            size: 85.88,
            width: 205,
            height: 156,
          },
        },
        hash: "Fleetwood_Hero_b62fd70389",
        ext: ".png",
        mime: "image/png",
        size: 443.74,
        url: "",
        previewUrl: null,
        provider: "aws-s3",
        provider_metadata: null,
        created_at: "2021-10-14T05:36:23.469Z",
        updated_at: "2021-10-14T05:36:24.206Z",
      },
    ],
  },
  Description: {
    id: 6,
    Copy: "Loading...",
    ImagePosition: "Right",
    Color: null,
    Title: "",
    Media: [],
    backgroundImage: null,
  },
  Cards: [
    {
      id: 13,
      CardContentTitle: null,
      CardContentBody: null,
      Title: null,
      Copy: null,
      Action: [
        {
          id: 4,
          Label: "Loading...",
          Link: null,
        },
      ],
      ZoomInIcon: [],
    },
  ],
};

export const defaultMapsPageData = {
  id: 1,

  Hero: {
    id: 5,
    HeroTitle: " ",
    HeroButton: null,
    HeroImage: [
      {
        id: 60,
        name: "hero-maps.png",
        alternativeText: "",
        caption: "",
        width: 582,
        height: 443,
        formats: {
          small: {
            ext: ".png",
            url: "",
            hash: "small_hero_maps_c212d62425",
            mime: "image/png",
            name: "small_hero-maps.png",
            path: null,
            size: 459.18,
            width: 500,
            height: 381,
          },
          thumbnail: {
            ext: ".png",
            url: "",
            hash: "thumbnail_hero_maps_c212d62425",
            mime: "image/png",
            name: "thumbnail_hero-maps.png",
            path: null,
            size: 91.28,
            width: 205,
            height: 156,
          },
        },
        hash: "hero_maps_c212d62425",
        ext: ".png",
        mime: "image/png",
        size: 447.74,
        url: "",
        previewUrl: null,
        provider: "aws-s3",
        provider_metadata: null,
      },
    ],
  },
  Description: {
    __component: "section.image-text",
    id: 5,
    Copy: "Loading ",
    ImagePosition: "Left",
    Color: null,
    Title: "# WHERE YOU **WANT TO BE**",
    Media: [
      {
        id: 60,
        name: "hero-maps.png",
        alternativeText: "",
        caption: "",
        width: 582,
        height: 443,
        formats: {
          small: {
            ext: ".png",
            url: "",
            hash: "small_hero_maps_c212d62425",
            mime: "image/png",
            name: "small_hero-maps.png",
            path: null,
            size: 459.18,
            width: 500,
            height: 381,
          },
          thumbnail: {
            ext: ".png",
            url: "",
            hash: "thumbnail_hero_maps_c212d62425",
            mime: "image/png",
            name: "thumbnail_hero-maps.png",
            path: null,
            size: 91.28,
            width: 205,
            height: 156,
          },
        },
        hash: "hero_maps_c212d62425",
        ext: ".png",
        mime: "image/png",
        size: 447.74,
        url: "",
        previewUrl: null,
        provider: "aws-s3",
        provider_metadata: null,
      },
    ],
    backgroundImage: null,
  },

  MapFiles: [
    {
      Name: "Retail",
      Maps: {
        id: 7,
        Map: {
          id: 76,
          name: "map-retail.svg",
          alternativeText: "",
          caption: "",
          width: 770,
          height: 656,
          formats: null,
          hash: "map_retail_e73e59cc8b",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 119.63,
          url: "",
          previewUrl: null,
          provider: "aws-s3",
          provider_metadata: null,
        },
        Legend: {
          id: 82,
          name: "legend-retail.svg",
          alternativeText: "",
          caption: "",
          width: 352,
          height: 433,
          formats: null,
          hash: "legend_retail_d5735d78fa",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 90.95,
          url: "",
          previewUrl: null,
          provider: "aws-s3",
          provider_metadata: null,
        },
      },
    },
  ],
};
export const defaultFloorplansPageData = {
  Hero: {
    id: 4,
    HeroTitle: " ",
    HeroButton: null,
    HeroImage: [
      {
        id: 24,
        name: "FloorplansHero.png",
        alternativeText: "",
        caption: "",
        width: 583,
        height: 443,
        formats: {
          small: {
            ext: ".png",
            url: "",
            hash: "small_Floorplans_Hero_609cee8e05",
            mime: "image/png",
            name: "small_FloorplansHero.png",
            path: null,
            size: 293.89,
            width: 500,
            height: 380,
          },
          thumbnail: {
            ext: ".png",
            url: "",
            hash: "thumbnail_Floorplans_Hero_609cee8e05",
            mime: "image/png",
            name: "thumbnail_FloorplansHero.png",
            path: null,
            size: 61.46,
            width: 205,
            height: 156,
          },
        },
        hash: "Floorplans_Hero_609cee8e05",
        ext: ".png",
        mime: "image/png",
        size: 324.43,
        url: "",
        previewUrl: null,
        provider: "aws-s3",
        provider_metadata: null,
      },
    ],
  },
  Simplicity: {
    __component: "section.card-text-section",
    id: 11,
    CardContentTitle: null,
    CardContentBody: null,
    Title: "Title",
    Copy: "...",
    Action: [
      {
        id: 3,
        Label: "Watch Video",
        Link: "",
      },
    ],
    Media: [],
  },
  Quality: {
    Copy: "...",
    ImagePosition: "Right",
    Color: null,
    Title: "",
    Media: [],
    backgroundImage: null,
  },
  Furnished: {
    Copy: "...",
    ImagePosition: "Left",
    Color: null,
    Title: "",
    Media: [],
    backgroundImage: null,
  },
  Specifications: "",
  units: [],
};
export const defaultAmenitiesPageData = {
  Hero: {
    id: 3,
    HeroTitle: "",
    HeroButton: null,
    HeroImage: [
      {
        id: 23,
        name: "AmenitiesHero.png",
        alternativeText: "",
        caption: "",
        width: 604,
        height: 443,
        formats: {
          small: {
            ext: ".png",
            url: "",
            hash: "small_Amenities_Hero_4b6ae3744c",
            mime: "image/png",
            name: "small_AmenitiesHero.png",
            path: null,
            size: 468.97,
            width: 500,
            height: 367,
          },
          thumbnail: {
            ext: ".png",
            url: "",
            hash: "thumbnail_Amenities_Hero_4b6ae3744c",
            mime: "image/png",
            name: "thumbnail_AmenitiesHero.png",
            path: null,
            size: 94.72,
            width: 213,
            height: 156,
          },
        },
        hash: "Amenities_Hero_4b6ae3744c",
        ext: ".png",
        mime: "image/png",
        size: 554.47,
        url: "",
        previewUrl: null,
        provider: "aws-s3",
        provider_metadata: null,
        created_by: 1,
        updated_by: 1,
        created_at: "2021-10-07T18:07:53.637Z",
        updated_at: "2021-10-07T18:07:53.856Z",
      },
    ],
  },
  Description: {
    id: 1,
    Title: "# OUR **AMENITIES**",
    Body: "Oliver features an abundance of outdoor space including dog friendly areas, seating, fire pits, community garden, recreation, fitness and wellness space, dining and shared BBQ areas. The interior amenities will provide additional community gathering space, kitchen, lounge, games room, entertainment, and pet wash uses for residents. Resident lobbies will include a main floor bar, parcel storage, and concierge for resident convenience and accessibility.",
  },
  Cards: [],
};

export const defaultBusinessData = {
  Name: "The Oliver Apartments",
  MailingAddress: null,
  Website: "oliveron10th.com",
  City: "Calgary",
  StateProvince: "AB",
  PostalCode: null,
  ContactEmail: null,
  Telephone: null,
};
export const defaultTeamData = {
  Hero: {
    id: 9,
    HeroTitlex: null,
    HeroTitle: " ",
    HeroButton: null,
    HeroImage: [
      {
        id: null,
        name: "SustainabilityHero.jpg",
        alternativeText: "",
        caption: "",
        width: 1920,
        height: 380,
        ext: ".jpg",
        mime: "image/jpeg",
        size: 112.82,
        url: "",
      },
    ],
  },
  project_developers: [
    {
      DevelopmentGroup: {
        Name: "",
        MailingAddress: "",
        Website: "",
        City: "",
        StateProvince: "",
        PostalCode: "",
        ContactEmail: null,
        Telephone: "",
        MailingAddress2: "",
        Fax: "",
        Description: "",
      },
      Logo: {
        alternativeText: "",
        caption: "",
        url: "",
        height: 100,
        width: 100,
      },
    },
  ],
};
export const defaultHomeData = {
  hero: {
    MainTitle: "",
    MainTitleCopy: "",
    MainTitleBackgroundImage: {
      width: 1366,
      height: 767,
      url: "",
    },
    MainTitleFeatureImage: {
      width: 584,
      height: 768,
      url: "",
    },
  },
  Welcome: {
    Copy: "",
    ImagePosition: "Left",
    Color: null,
    Title: "",
    ZoomInIcon: [],
    Action: [
      {
        Label: "",
        Link: "",
      },
    ],
    backgroundImage: null,
  },
  Gallery: null,
  Finer: {
    Copy: "",
    ImagePosition: "Left",
    Color: null,
    Title: "",
    Media: [],
    backgroundImage: null,
  },
  Green: {
    Copy: "",
    ImagePosition: "Left",
    Color: null,
    Title: "",
    Media: [{ url: "" }],
    backgroundImage: null,
  },
};
export const defaultLocationData = {
  id: 1,
  EconomicFact: "",
  created_at: "",
  updated_at: "",
  Hero: {
    id: 4,
    HeroTitlex: null,
    HeroTitle: "",
    HeroButton: null,
    HeroImage: [
      {
        id: 42,
        name: "",
        alternativeText: "",
        caption: "",
        width: 1920,
        height: 380,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 245,
            height: 48,
            size: 1.88,
            path: null,
            url: "",
          },
          large: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 1000,
            height: 198,
            size: 21.89,
            path: null,
            url: "",
          },
          medium: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 750,
            height: 148,
            size: 11.4,
            path: null,
            url: "",
          },
          small: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 500,
            height: 99,
            size: 5.42,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 92.21,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
  },
  MainCopy: {
    id: 2,
    Title: "",
    Body: "",
  },
  MapThumbnailCopy: [],
  LocationMapThumbnails: {
    id: 1,
    MapThumbnails: [
      {
        id: 1,
        Title: "",
        Description: "",
        Image: [
          {
            id: 21,
            name: "",
            alternativeText: null,
            caption: null,
            width: 1939,
            height: 1137,
            formats: {
              thumbnail: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 245,
                height: 144,
                size: 6.44,
                path: null,
                url: "",
              },
              large: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 1000,
                height: 586,
                size: 65.58,
                path: null,
                url: "",
              },
              medium: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 750,
                height: 440,
                size: 41.48,
                path: null,
                url: "",
              },
              small: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 500,
                height: 293,
                size: 22.57,
                path: null,
                url: "",
              },
            },
            hash: "",
            ext: "",
            mime: "",
            size: 164.89,
            url: "",
            previewUrl: null,
            provider: "",
            provider_metadata: null,
            created_at: "",
            updated_at: "",
          },
        ],
        PDF: {
          id: 56,
          name: "",
          alternativeText: "",
          caption: "",
          width: null,
          height: null,
          formats: null,
          hash: "",
          ext: "",
          mime: "",
          size: 752.81,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      },
      {
        id: 2,
        Title: "",
        Description: "",
        Image: [
          {
            id: 22,
            name: "",
            alternativeText: null,
            caption: null,
            width: 1925,
            height: 1132,
            formats: {
              thumbnail: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 245,
                height: 144,
                size: 7.02,
                path: null,
                url: "",
              },
              large: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 1000,
                height: 588,
                size: 72.03,
                path: null,
                url: "",
              },
              medium: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 750,
                height: 441,
                size: 45.44,
                path: null,
                url: "",
              },
              small: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 500,
                height: 294,
                size: 24.92,
                path: null,
                url: "",
              },
            },
            hash: "",
            ext: "",
            mime: "",
            size: 179.22,
            url: "",
            previewUrl: null,
            provider: "",
            provider_metadata: null,
            created_at: "",
            updated_at: "",
          },
        ],
      },
      {
        id: 3,
        Title: "",
        Description: "",
        Image: [
          {
            id: 23,
            name: "",
            alternativeText: null,
            caption: null,
            width: 1920,
            height: 1127,
            formats: {
              thumbnail: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 245,
                height: 144,
                size: 10.01,
                path: null,
                url: "",
              },
              large: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 1000,
                height: 587,
                size: 91.89,
                path: null,
                url: "",
              },
              medium: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 750,
                height: 440,
                size: 58.29,
                path: null,
                url: "",
              },
              small: {
                name: "",
                hash: "",
                ext: "",
                mime: "",
                width: 500,
                height: 293,
                size: 32.82,
                path: null,
                url: "",
              },
            },
            hash: "",
            ext: "",
            mime: "",
            size: 229.12,
            url: "",
            previewUrl: null,
            provider: "",
            provider_metadata: null,
            created_at: "",
            updated_at: "",
          },
        ],
      },
    ],
  },
  EconomicData: {
    id: 3,
    Title: "",
    IconText: [
      {
        id: 13,
        order: 1,
        Copy: "",
        Icon: {
          id: 29,
          name: "",
          alternativeText: null,
          caption: null,
          width: 102,
          height: 101,
          formats: null,
          hash: "",
          ext: "",
          mime: "",
          size: 0.92,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      },
      {
        id: 16,
        order: 2,
        Copy: "",
        Icon: {
          id: 28,
          name: "",
          alternativeText: null,
          caption: null,
          width: 102,
          height: 101,
          formats: null,
          hash: "",
          ext: "",
          mime: "",
          size: 1.09,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      },
      {
        id: 17,
        order: 3,
        Copy: "",
        Icon: {
          id: 24,
          name: "",
          alternativeText: null,
          caption: null,
          width: 102,
          height: 101,
          formats: null,
          hash: "",
          ext: "",
          mime: "",
          size: 1.28,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      },
      {
        id: 18,
        order: 4,
        Copy: "",
        Icon: {
          id: 26,
          name: "",
          alternativeText: null,
          caption: null,
          width: 102,
          height: 101,
          formats: null,
          hash: "",
          ext: "",
          mime: "",
          size: 1.25,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      },
      {
        id: 14,
        order: 5,
        Copy: "",
        Icon: {
          id: 27,
          name: "",
          alternativeText: null,
          caption: null,
          width: 102,
          height: 101,
          formats: null,
          hash: "",
          ext: "",
          mime: "",
          size: 0.96,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      },
      {
        id: 15,
        order: 6,
        Copy: "",
        Icon: {
          id: 25,
          name: "",
          alternativeText: null,
          caption: null,
          width: 102,
          height: 101,
          formats: null,
          hash: "",
          ext: "",
          mime: "",
          size: 1.8,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      },
    ],
  },
  BottomGallery: {
    id: 2,
    Title: "",
    Images: [
      {
        id: 10,
        name: "",
        alternativeText: null,
        caption: null,
        width: 495,
        height: 407,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 190,
            height: 156,
            size: 7.94,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 44.63,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
      {
        id: 11,
        name: "",
        alternativeText: null,
        caption: null,
        width: 495,
        height: 407,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 190,
            height: 156,
            size: 7.77,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 39.87,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
      {
        id: 13,
        name: "",
        alternativeText: null,
        caption: null,
        width: 248,
        height: 204,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 190,
            height: 156,
            size: 6.59,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 10.29,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
  },
  AccessImage: {
    id: 19,
    name: "",
    alternativeText: null,
    caption: null,
    width: 1925,
    height: 1143,
    formats: {
      thumbnail: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 245,
        height: 145,
        size: 5.4,
        path: null,
        url: "",
      },
      large: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 1000,
        height: 594,
        size: 65.92,
        path: null,
        url: "",
      },
      medium: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 750,
        height: 445,
        size: 39.73,
        path: null,
        url: "",
      },
      small: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 500,
        height: 297,
        size: 20.13,
        path: null,
        url: "",
      },
    },
    hash: "",
    ext: "",
    mime: "",
    size: 174.44,
    url: "",
    previewUrl: null,
    provider: "",
    provider_metadata: null,
    created_at: "",
    updated_at: "",
  },
  Skyline: {
    id: 20,
    name: "",
    alternativeText: null,
    caption: null,
    width: 1920,
    height: 407,
    formats: {
      thumbnail: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 245,
        height: 52,
        size: 4,
        path: null,
        url: "",
      },
      large: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 1000,
        height: 212,
        size: 44.33,
        path: null,
        url: "",
      },
      medium: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 750,
        height: 159,
        size: 25.44,
        path: null,
        url: "",
      },
      small: {
        name: "",
        hash: "",
        ext: "",
        mime: "",
        width: 500,
        height: 106,
        size: 12.7,
        path: null,
        url: "",
      },
    },
    hash: "",
    ext: "",
    mime: "",
    size: 146.27,
    url: "",
    previewUrl: null,
    provider: "",
    provider_metadata: null,
    created_at: "",
    updated_at: "",
  },
};
export const defaultLeasingData = {
  id: 1,
  created_at: "",
  updated_at: "",
  Hero: {
    id: 2,
    HeroTitlex: null,
    HeroTitle: "",
    HeroButton: null,
    HeroImage: [
      {
        id: 18,
        name: "",
        alternativeText: null,
        caption: null,
        width: 1920,
        height: 508,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 245,
            height: 65,
            size: 5.78,
            path: null,
            url: "",
          },
          large: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 1000,
            height: 265,
            size: 54.86,
            path: null,
            url: "",
          },
          medium: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 750,
            height: 198,
            size: 33.76,
            path: null,
            url: "",
          },
          small: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 500,
            height: 132,
            size: 18.5,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 168.74,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
  },
  MainCopy: {
    id: 1,
    Title: "",
    Body: "",
  },
};

export const defaultPropertyDetailsData = {
  id: 1,
  created_at: "",
  updated_at: "",
  Hero: [
    {
      id: 3,
      HeroTitlex: null,
      HeroTitle: "",
      HeroButton: null,
      HeroImage: [
        {
          id: 2,
          name: "",
          alternativeText: "",
          caption: "",
          width: 1920,
          height: 672,
          formats: {
            thumbnail: {
              name: "",
              hash: "",
              ext: "",
              mime: "",
              width: 245,
              height: 86,
              size: 4.98,
              path: null,
              url: "",
            },
            large: {
              name: "",
              hash: "",
              ext: "",
              mime: "",
              width: 1000,
              height: 350,
              size: 40.78,
              path: null,
              url: "",
            },
            medium: {
              name: "",
              hash: "",
              ext: "",
              mime: "",
              width: 750,
              height: 263,
              size: 26.77,
              path: null,
              url: "",
            },
            small: {
              name: "",
              hash: "",
              ext: "",
              mime: "",
              width: 500,
              height: 175,
              size: 14.6,
              path: null,
              url: "",
            },
          },
          hash: "",
          ext: "",
          mime: "",
          size: 98.89,
          url: "",
          previewUrl: null,
          provider: "",
          provider_metadata: null,
          created_at: "",
          updated_at: "",
        },
      ],
    },
  ],
  LocalAmenities: {
    id: 3,
    Copy: "",
    ImagePosition: "",
    Color: "",
    Title: "",
    Image: [
      {
        id: 21,
        name: "",
        alternativeText: null,
        caption: null,
        width: 1939,
        height: 1137,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 245,
            height: 144,
            size: 6.44,
            path: null,
            url: "",
          },
          large: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 1000,
            height: 586,
            size: 65.58,
            path: null,
            url: "",
          },
          medium: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 750,
            height: 440,
            size: 41.48,
            path: null,
            url: "",
          },
          small: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 500,
            height: 293,
            size: 22.57,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 164.89,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
    backgroundImage: null,
  },
};

export const defaultSpecificationsData = [
  {
    id: 1,
    Name: "",
    created_at: "",
    updated_at: "",
    Specification: [
      {
        id: 1,
        Name: "",
        Description: "",
        Order: 1,
      },
    ],
  },
];

export const defaultGalleryData = {
  id: 1,
  created_at: "",
  updated_at: "",
  Hero: {
    id: 6,
    HeroTitlex: null,
    HeroTitle: "",
    HeroButton: null,
    HeroImage: [
      {
        id: 54,
        name: "",
        alternativeText: "",
        caption: "",
        width: 1920,
        height: 380,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 245,
            height: 48,
            size: 2.85,
            path: null,
            url: "",
          },
          large: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 1000,
            height: 198,
            size: 28.45,
            path: null,
            url: "",
          },
          medium: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 750,
            height: 148,
            size: 16.63,
            path: null,
            url: "",
          },
          small: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 500,
            height: 99,
            size: 8.57,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 91.24,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
  },
  Gallery: [
    {
      id: 36,
      name: "",
      alternativeText: "",
      caption: "",
      width: 1600,
      height: 1000,
      formats: {
        thumbnail: {
          name: "",
          hash: "",
          ext: "",
          mime: "",
          width: 245,
          height: 153,
          size: 9.33,
          path: null,
          url: "",
        },
        large: {
          name: "",
          hash: "",
          ext: "",
          mime: "",
          width: 1000,
          height: 625,
          size: 119.67,
          path: null,
          url: "",
        },
        medium: {
          name: "",
          hash: "",
          ext: "",
          mime: "",
          width: 750,
          height: 469,
          size: 67.94,
          path: null,
          url: "",
        },
        small: {
          name: "",
          hash: "",
          ext: "",
          mime: "",
          width: 500,
          height: 313,
          size: 32.67,
          path: null,
          url: "",
        },
      },
      hash: "",
      ext: "",
      mime: "",
      size: 223.3,
      url: "",
      previewUrl: null,
      provider: "",
      provider_metadata: null,
      created_at: "",
      updated_at: "",
    },
  ],
};
export const defaultSustainabilityData = {
  id: 1,
  published_at: "",
  created_at: "",
  updated_at: "",
  Hero: {
    id: 5,
    HeroTitlex: null,
    HeroTitle: "",
    HeroButton: null,
    HeroImage: [
      {
        id: 52,
        name: "",
        alternativeText: "",
        caption: "",
        width: 1920,
        height: 380,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 245,
            height: 48,
            size: 3.27,
            path: null,
            url: "",
          },
          large: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 1000,
            height: 198,
            size: 32.83,
            path: null,
            url: "",
          },
          medium: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 750,
            height: 148,
            size: 19.39,
            path: null,
            url: "",
          },
          small: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 500,
            height: 99,
            size: 10.2,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 112.82,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
  },
  TargetingLeedGold: {
    id: 4,
    Copy: "",
    ImagePosition: "",
    Color: "",
    Title: "",
    Image: [
      {
        id: 53,
        name: "",
        alternativeText: "",
        caption: "",
        width: 673,
        height: 633,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 166,
            height: 156,
            size: 73.38,
            path: null,
            url: "",
          },
          small: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 500,
            height: 470,
            size: 565.45,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 679.74,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
    backgroundImage: null,
  },
  MainCopy: {
    id: 3,
    Title: "",
    Body: "",
  },
};
export const defaultContactPageData = {
  id: 1,
  published_at: "",
  created_at: "",
  updated_at: "",
  Hero: {
    id: 7,
    HeroTitlex: null,
    HeroTitle: "",
    HeroButton: null,
    HeroImage: [
      {
        id: 42,
        name: "",
        alternativeText: "",
        caption: "",
        width: 1920,
        height: 380,
        formats: {
          thumbnail: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 245,
            height: 48,
            size: 1.88,
            path: null,
            url: "",
          },
          large: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 1000,
            height: 198,
            size: 21.89,
            path: null,
            url: "",
          },
          medium: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 750,
            height: 148,
            size: 11.4,
            path: null,
            url: "",
          },
          small: {
            name: "",
            hash: "",
            ext: "",
            mime: "",
            width: 500,
            height: 99,
            size: 5.42,
            path: null,
            url: "",
          },
        },
        hash: "",
        ext: "",
        mime: "",
        size: 92.21,
        url: "",
        previewUrl: null,
        provider: "",
        provider_metadata: null,
        created_at: "",
        updated_at: "",
      },
    ],
  },
  MainCopy: {
    id: 4,
    Title: "",
    Body: "",
  },
};
export const defaultContactsData = [
  {
    id: 1,
    Position: "",
    Company: "",
    Telephone: "",
    Email: "",
    Name: "",
    created_at: "",
    updated_at: "",
  },
];
