import React, { useState } from "react";
import styled from "@emotion/styled";

import axios from "axios";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Alert as MuiAlert,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { Event } from "./Tracking";

function MailChimpForm({ open, setOpen, setMessageSent, ...props }) {
  const [formData, setFormData] = React.useState({
    FNAME: "",
    LNAME: "",
    EMAIL: "",
    PHONE: "",
    MOVEIN: "",
    PETS: "",
    ROOMS: "",
    PARKING: "",
    ECAR: "",
    FURNISHED: "",
  });

  const [sending, setSending] = useState(false);
  const [reCaptchaValid, setReCaptchValid] = React.useState(false);
  const [reCaptchaMessage, setReCaptchaMessage] = React.useState(false);

  const [validationErrors, setValidationErrors] = React.useState({
    FNAME: null,
    LNAME: null,
    EMAIL: null,
  });
  const [error, setError] = React.useState("");

  const handleOnFormChange = e => {
    setFormData({
      ...formData,
      [Object.keys(formData).filter(key => {
        if (key === e.currentTarget.attributes.name.value) {
          return e.currentTarget.attributes.name.value;
        }
        return false;
      })]: e.currentTarget.value,
    });
  };

  const handleFormErrorClose = () => {
    setError("");
  };

  function onChange(value) {
    setReCaptchValid(true);
    setReCaptchaMessage(false);
    return true;
  }

  function onErrored() {
    console.error(`Error validating reCaptcha. Check network connection.`);
    return false;
  }
  function validateForm(formElements) {
    const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim;

    formElements.EMAIL.value.search(regex);
    if (formElements.EMAIL.value.search(regex) === -1) {
      // The result can be accessed through the `m`-variable.
      setValidationErrors({ EMAIL: "This is not a valid email" });
      setSending(false);
      return;
    }
    setValidationErrors({ EMAIL: null });
  }

  const handleOnSubmit = event => {
    event.preventDefault();
    setSending(true);
    validateForm(event.target.elements);
    if (!reCaptchaValid) {
      setReCaptchaMessage(true);
      setSending(false);
      console.error("reCaptcha not valid");
      return false;
    }
    axios({
      method: "POST",
      url: `https://smhhxq1di4.execute-api.us-east-1.amazonaws.com`,
      // url: `https://xf9jak93q9.execute-api.us-east-1.amazonaws.com/production`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: formData,
    })
      .then(res => {
        if (res.data.hasOwnProperty("_links")) {
          setSending(false);
          setMessageSent(true);
          Event("Register", "Sent", "true");
          return true;
        }

        if (res.data.hasOwnProperty("status")) {
          if (res.data.title === "Member Exists") {
            const regex = /list member./gi;
            const detail = res.data.detail;
            const message = detail.substring(0, detail.search(regex) + 12);

            setValidationErrors({ EMAIL: message });
            setSending(false);
            return false;
          }

          setSending(false);
          return false;
        }
      })
      .catch(error => {
        console.error(error.response.data);
      });

    return true;
  };
  const textFormFields = [
    {
      name: "FNAME",
      label: "First Name",
      required: true,
      type: "text",
    },
    {
      name: "LNAME",
      label: "Last Name",
      required: true,
      type: "text",
    },
    {
      name: "EMAIL",
      label: "Email Address",
      required: true,
      type: "email",
    },
  ];
  const radioFormFields = [
    {
      name: "PETS",
      label: "Do you have any pets?",
      required: false,
      values: ["Yes", "No"],
      row: false,
    },
    {
      name: "PARKING",
      label: "Do you need parking?",
      required: false,
      values: ["Yes", "No"],
      row: false,
    },
    {
      name: "ECAR",
      label: "Do you drive an E-Car?",
      required: false,
      values: ["Yes", "No"],
      row: false,
    },
    {
      name: "FURNISHED",
      label: "Furnished or unfurnished unit?",
      required: false,
      values: ["Furnished", "Unfurnished"],
      row: false,
    },
    {
      name: "ROOMS",
      label: "How many bedrooms do you need?",
      required: false,
      values: ["Studio", "One Bedroom", "Two Bedroom"],
      row: false,
    },
  ];

  return (
    <FormHeading>
      <StyledSignUp>
        <form
          onSubmit={handleOnSubmit}
          // action="https://oliveron10th.us20.list-manage.com/subscribe/post"
          // method="post"
          // validate="true"
        >
          <div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div>
          {textFormFields.map((field, index) => {
            const thisClass = field.required ? "required" : null;
            return (
              <React.Fragment key={field.name}>
                <TextField
                  onChange={handleOnFormChange}
                  value={formData[field.name]}
                  name={field.name}
                  id={`mce-${field.name}`}
                  className={`required ${validationErrors[field.name] ? "error" : null}`}
                  inputProps={{ className: thisClass }}
                  label={field.label}
                  variant="filled"
                  type={field.type}
                  required={field.required}
                  helperText={validationErrors[field.name]}
                />
                {/* {validationErrors[field.name] && (
                  <Typography style={{ color: "red" }} variant="caption">
                    {validationErrors[field.name]}
                  </Typography>
                )} */}
              </React.Fragment>
            );
          })}

          {/* <Box
            display="flex"
            flexDirection="row"
            alignContent="center"
            flexWrap="wrap"
            justifyContent="center"
            width="100%"
            maxWidth={720}
            align="left"
          >
            {radioFormFields.map((field, index) => {
              return (
                <FormControl
                  component="fieldset"
                  classes={{ root: "radio-fieldset" }}
                  key={field.name}
                >
                  <FormLabel classes={{ root: "radio-label" }} component="legend">
                    {field.label}
                  </FormLabel>
                  <RadioGroup
                    aria-label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleOnFormChange}
                    color="primary"
                    row={field.row}
                  >
                    {field.values.map((value, index) => {
                      return (
                        <FormControlLabel
                          key={`${field.name}${value}`}
                          value={value}
                          control={<Radio />}
                          label={value}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              );
            })}
          </Box> */}
          <ReCAPTCHA
            className={`captcha ${reCaptchaMessage ? "captchaError" : null}`}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={onChange}
            onErrored={onErrored}
            style={{ marginBottom: "2rem" }}
          />

          <Button type="submit" variant="contained">
            {sending && (
              <CircularProgress
                variant="indeterminate"
                thickness={6}
                color="inherit"
                size="1rem"
                style={{ marginLeft: "1rem" }}
              />
            )}
            Register
          </Button>

          {error.length > 0 && (
            <MuiAlert onClose={handleFormErrorClose} severity="error">
              <Typography variant="h5" color="textPrimary">
                ERROR! Message not sent!
              </Typography>
              <Typography variant="p" color="textPrimary">
                {error}
              </Typography>
            </MuiAlert>
          )}
        </form>
      </StyledSignUp>
    </FormHeading>
  );
}

const FormHeading = styled("div")`
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: center;
  @media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    flex-direction: column;
  }
`;

const StyledSignUp = styled("div")`
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;

  color: #fff;
  /* margin-left: 8%; */
  margin-top: 2rem;
  @media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    margin-top: unset;
  }
  .captchaError > div > div {
    border: red 4px solid;
  }
  .error > div {
    border: red 4px solid;
  }
  .size1of2 {
    min-width: 260px;
  }
  button {
    margin-bottom: 80px;
  }
  form {
    /* max-width: 720px; */
  }
  .MuiFormHelperText-root {
    font-size: 0.9rem;
  }
  .MuiFormLabel-root {
    color: ${props => props.theme.palette.primary.main};
    font-size: 1.1rem;
  }
  .MuiFilledInput-root {
    color: ${props => props.theme.palette.primary.main};
    background-color: white;
  }
  .MuiFormControl-root:last-of-type {
    ${props => props.theme.breakpoints.up("sm")} {
      margin-left: 0.5rem;
    }
    ${props => props.theme.breakpoints.up("md")} {
      margin-left: 2rem;
    }
  }
  .MuiFilledInput-input {
  }
  .MuiInputBase-input {
    min-width: 280px;
    width: 21vw;
    height: 30px;
    font-size: 1rem;
    padding: 20px 12px 5px;
    color: ${props => props.theme.palette.primary.dark};
    ${props => props.theme.breakpoints.up("sm")} {
      min-width: 270px;
    }
    ${props => props.theme.breakpoints.up("md")} {
    }
  }
  .radio-label,
  .MuiIconButton-label,
  .MuiFormControlLabel-label {
    color: ${props => props.theme.palette.common.white}!important;
  }
  .MuiFormControlLabel-label {
    margin-left: 0.4rem;
  }
  .MuiFormControlLabel-root {
    margin: 0.3rem;
  }
  .MuiTextField-root {
    margin: 0.5rem;
  }
  .MuiFormGroup-root {
    padding-left: 1rem;
  }

  .radio-fieldset {
    margin: 2rem;
    flex-direction: row;
    > .radio-label {
      margin-bottom: 0.5rem;
    }
  }
  .MuiIconButton-root {
    background-color: rgb(255, 255, 255, 0.25);
  }
  .MuiFormControl-root {
    min-width: 275px;
  }
`;

export default MailChimpForm;
