import React from "react";
import PropTypes from "prop-types";
import { Button as ButtonMui } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => {
  return {
    root: {
      margin: ".5em",
      padding: "0.5em",
      width: 200,
      height: 65,
      textTransform: "uppercase",
      borderRadius: 0,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      fontFamily: "pt-sans-pro-condensed, sans-serif",
      fontWeight: 700,
      fontStyle: "normal",
      fontSize: "1em",
      lineHeight: "1em",
      [theme.breakpoints.up("sm")]: {
        margin: "1em",
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
      "&:active": {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
      },
      "&.selected": {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
      },
    },
    navItem: {
      margin: "0",
      fontWeight: theme.typography.fontWeightBold,
    },
  };
});
function Button(props) {
  const classes = useStyles();

  return (
    <ButtonMui
      {...props}
      target={props.target || "_self"}
      href={props.href}
      variant="contained"
      className={`${classes.root} ${props.className}`}
    >
      {props.children}
    </ButtonMui>
  );
}

Button.propTypes = {
  target: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Button;
