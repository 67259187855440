import React from "react";
import styled from "@emotion/styled";
import { Container, Grid, Typography, Link } from "@mui/material";
import EmailIcon from "./images/EmailIcon.svg";
import LocationIcon from "./images/LocationIcon.svg";
import PhoneIcon from "./images/PhoneIcon.svg";
import SocialIcons from "./SocialIcons";

export default function Footer() {
  //
  const year = new Date().getFullYear();
  return (
    <Container maxWidth={false} disableGutters>
      <Wrapper container justifyContent={"space-evenly"}>
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"center"}
          alignContent={"center"}
          direction={"column"}
          item
          xs={12}
          sm={12}
          md={4}
          style={{ paddingBottom: "3rem" }}
        >
          <StyledIcons src={PhoneIcon} alt="" width="66" height="66" />
          <Typography variant="h1" paragraph>
            Phone
          </Typography>
          <Typography variant="body1" paragraph>
            Concierge
            <br />
            825-413-0700
          </Typography>
          <Typography variant="body1">
            Leasing
            <br />
            403-700-0866
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"center"}
          alignContent={"center"}
          direction={"column"}
          item
          xs={12}
          sm={12}
          md={4}
          style={{ paddingBottom: "3rem" }}
        >
          <StyledIcons src={LocationIcon} alt="" width="66" height="66" />
          <Typography variant="h1" paragraph>
            Location
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Oliver Presentation Gallery</strong>
          </Typography>
          <Typography variant="body1" paragraph style={{ whiteSpace: "pre" }}>
            {`524 & 538 10th Ave SW
Calgary, AB
Monday - Friday: 9am - 6pm
Saturday & Sunday: By Appointment Only
Closed on Holidays`}
          </Typography>
          <Typography variant="body1">
            <strong>Centron Main Office</strong>
            <br />
            #104, 8826 Blackfoot Trail SE
          </Typography>
          <Typography variant="body1">
            {`Centron Main Office
#104, 8826 Blackfoot Trail SE`}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"center"}
          alignContent={"center"}
          direction={"column"}
          item
          xs={12}
          sm={12}
          md={4}
        >
          <StyledIcons src={EmailIcon} alt="" width="66" height="66" />
          <Typography variant="h1" paragraph>
            Email
          </Typography>
          <Typography variant="body1">
            <Link href="mailto:leasing@oliveron10th.com" underline="none">
              Leasing@OliverOn10th.com
            </Link>
          </Typography>
        </Grid>
      </Wrapper>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        direction={"column"}
        alignContent={"center"}
        alignItems={"center"}
        style={{ backgroundColor: "rgba(179,175,165,1)", padding: "1.5rem" }}
      >
        <SocialIcons
          size="2.3rem"
          facebook="https://www.facebook.com/oliveron10th"
          instagram="https://www.instagram.com/oliveron10th/"
        />
        <Typography variant="caption" style={{ color: "white" }}>
          © {year} CENTRON. ALL RIGHTS RESERVED.
        </Typography>
      </Grid>
    </Container>
  );
}
const Wrapper = styled(Grid)`
  padding: 5rem 1rem 2rem;
  background-color: rgba(42, 48, 46, 1);
  ${props => props.theme.breakpoints.up("md")} {
    padding: 5rem 5rem 2rem;
  }
  p {
    color: ${({ theme }) => theme.palette.common.white};
  }
  a {
    color: ${({ theme }) => theme.palette.common.white};
  }
  img {
    padding-bottom: 3rem;
  }
`;
const StyledIcons = styled("img")``;
