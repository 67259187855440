import React from "react";
import styled from "@emotion/styled";
import { BackgroundImage } from "react-image-and-background-image-fade";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Typography } from "@mui/material";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
export default function ImageContainer({ isTouch, srcimage, alttext, ...props }) {
  return (
    <Wrapper
      src={srcimage}
      alt={alttext}
      {...props}
      lazyLoad
      role="presentation"
      disableLoader={true}
    >
      {isTouch && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            color: "black",
            padding: ".5rem",
          }}
        >
          <ZoomInIcon fontSize="large" />
        </div>
      )}
      {!isTouch && (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
            left: 0,
            top: 25,
            height: "fit-content",
            width: "fit-content",
            color: "white",
            backgroundColor: "rgb(196,196,196)",
            padding: ".5rem",
          }}
        >
          <CenterFocusWeakIcon />
          <Typography variant="caption">Click to Expand</Typography>
        </div>
      )}
    </Wrapper>
  );
}
const Wrapper = styled(BackgroundImage)`
  display: flex;
  justify-content: flex-start;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 768px;
  height: 100vh;
  max-height: ${props => (props?.maxHeight ? props?.maxHeight : 610)}px;
  &:hover {
    cursor: zoom-in;
  }
  .loader {
    background-color: red;
    height: 200px;
  }
`;
