import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider as ThemeProviderSC } from "@emotion/react";
import smoothscroll from "smoothscroll-polyfill"; //https://github.com/iamdustan/smoothscroll

// Note that the unstable version unstable_createMuiStrictModeTheme is used here to get around the warning generated by strict mode.
// In version 5 of MUI this should be resolved.
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "./Theme";
smoothscroll.polyfill();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProviderSC theme={theme}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ThemeProviderSC>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
