import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Container, Grid, Button, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import { InView } from "react-intersection-observer";
import Map from "./images/map.svg";
import MapLegend from "./images/mapLegend.svg";

function MapSection(props) {
  const [inView, setInView] = React.useState(false);

  return (
    <MapSectionContainer component="section" maxWidth={false} disableGutters>
      <Container maxWidth="lg" disableGutters>
        <Grid container wrap="wrap" justifyContent="space-between" alignItems="center">
          <InView>
            {({ inView, ref, entry }) => {
              return (
                <Grid ref={ref} style={{ position: "relative" }} item xs={12} sm={6}>
                  {inView && <ImageContainer image={Map}></ImageContainer>}
                </Grid>
              );
            }}
          </InView>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              className="legend-container"
            >
              <img src={MapLegend} className="legend" alt="The Oliver Area Map" />
              <ButtonWrapper>
                <Button variant="contained" component={Link} href="https://oliveron10th.com/maps">
                  View Maps
                </Button>
              </ButtonWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MapSectionContainer>
  );
}
const ButtonWrapper = styled("div")`
  width: 100%;
  max-width: 400px;
  text-align: left;
`;
const ImageContainer = styled("div")`
  background: url(${props => props.image}) center/cover no-repeat;
  min-height: 275px;

  ${props => props.theme.breakpoints.up("sm")} and (orientation: portrait) {
    min-height: 337px;
  }
  ${props => props.theme.breakpoints.up("sm")} and (orientation: landscape) {
    min-height: 422px;
  }
  ${props => props.theme.breakpoints.up("md")} and (orientation: portrait) {
    min-height: 543px;
  }
  ${props => props.theme.breakpoints.up("md")} and (orientation: landscape) {
    min-height: 434px;
  }
  ${props => props.theme.breakpoints.up("lg")} {
    min-height: 543px;
  }
`;
const MapSectionContainer = styled(Container)`
  /* background-color: #f5f5f5; */

  .MuiButtonBase-root {
    margin: 2rem 0 0 0;
  }
  img.legend {
    width: 100%;
    height: auto;
    max-width: 400px;
  }
  div.legend-container {
    padding: 3rem 1rem 3rem 1rem;
    ${props => props.theme.breakpoints.up("md")} {
      margin: 0 16px;
    }
  }
`;

MapSection.propTypes = {};

export default MapSection;
