import react, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import logo from "./logo.svg";
import "./App.css";
import { useTheme, useMediaQuery, Grid, Container, Fab, Typography, Box } from "@mui/material";
import FloorplansMadison from "./FloorplansMadison";
import MarkdownSection from "./MarkdownSection";
import Hero from "./Hero";
import { initGA, PageView } from "./Tracking";
import { getData, source } from "./data";
import ClickableImage from "./ClickableImage";
import { scrollToTop, convertColor } from "./Functions";
import MapSection from "./MapSection";
import Video from "./Video";
import Footer from "./Footer";
import Register from "./Register";

import OliverWestTower from "./images/WestTower429.jpg";
import VenetaInterior from "./images/DZIVER-230307-_DSF2274.jpg";
import MadisonLogo from "./images/MadisonLogoWhite.svg";
import { defaultHomeData, defaultFloorplansPageData } from "./DefaultState";

function App(props) {
  //
  const theme = useTheme();

  const [homeData, setHomeData] = useState(defaultHomeData);
  const [madisonFloorplansPageData, setMadisonFloorplansPageData] =
    useState(defaultFloorplansPageData);
  const [MadisonHeroImage, setMadisonHeroImage] = useState();
  // const [MadisonLogo, setMadisonLogo] = useState();
  const [isTouch, setIsTouch] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isXsmall = useMediaQuery(theme.breakpoints.only("xs"));
  useEffect(() => {
    getData(setHomeData, "/home");
    // getData(setNavigationData, "/navigation", "NavData");
    // getData(setContactPageData, "/contact");
    // getData(setAmenitiesPageData, "/amenities");
    // getData(setFloorplansPageData, "/floorplans");
    getData(setMadisonFloorplansPageData, "/madison-floorplan");
    getData(setMadisonHeroImage, "/upload/files/652");
    // getData(setMadisonLogo, "/upload/files/438");
    // getData(setFleetwoodData, "/fleetwood"); //Fleetwood is now Luca
    // getData(setMapsPageData, "/maps");
    // getData(setOliverFurnishedData, "/oliver-furnished");
    return () => {
      source.cancel();
    };
  }, []);
  const handleOnTouchStart = event => {
    setIsTouch(true);
  };
  //homeData.Madison
  //   {
  //     "id": 10,
  //     "Copy": "Madison Avenue Executive Furnished Suites are designed for discerning leasees who value the comfort of an exclusive residence in Calgary’s newest downtown development. From the convenience of the Luca and its plethora of dining options to the amenities of The Oliver including its fitness centre, living at Madison Avenue is simply the best choice. Private patios, interiors by one of Calgary’s leading design firms, and a central location are further definitions of the Madison experience. Madison Avenue Executive Suites, make one yours at The Oliver.\n\n[SEE FLOORPLANS](/madison-floorplans)",
  //     "ImagePosition": null,
  //     "Color": null,
  //     "Title": "# MADISON AVENUE **EXECUTIVE FURNISHED SUITES**",
  //     "Media": [],
  //     "backgroundImage": null
  // }

  return (
    <Container
      className="App"
      disableGutters
      align="center"
      maxWidth="xl"
      onTouchStart={handleOnTouchStart}
    >
      <Hero
        data={{ heroImage: MadisonHeroImage, heroTitle: `# EXPERIENCE **LUXURY LIVING**` }}
        MadisonLogo={MadisonLogo}
        style={{
          backgroundColor: "#27262c",
          justify: "flex-start",
        }}
      />
      <Grid component={Container} container justifyContent="center" maxWidth="lg" disableGutters>
        <FullWidthImageWrapper
          style={{ marginTop: "2rem" }}
          src={VenetaInterior}
          alt="Veneta Suite"
        />

        <MarkdownSection
          options={{
            parent: "Home",
            imagePositionRight:
              madisonFloorplansPageData?.data?.attributes?.Distinctive[0]?.ImagePosition ===
              "Right",
            title: madisonFloorplansPageData?.data?.attributes?.Distinctive[0]?.Title,
            body: madisonFloorplansPageData?.data?.attributes?.Distinctive[0]?.Copy,
            color: convertColor(homeData?.Madison?.Color),
            textColor: "rgba(255,255,255,1)",
            titleColor: "rgba(254, 200, 87, 1)",
            cols: isSmall || isXsmall ? 12 : 8,
          }}
          // lazy={false}
        />
      </Grid>
      <FloorplansMadison
        PageView={PageView}
        convertColor={convertColor}
        theme={theme}
        data={madisonFloorplansPageData}
        MadisonHeroImage={MadisonHeroImage}
        MadisonLogo={MadisonLogo}
        isSmall={isSmall}
        isXsmall={isXsmall}
        isTouch={isTouch}
      />

      <Register />

      <Grid component={Container} container justifyContent="center" maxWidth="lg" disableGutters>
        <FullWidthImageWrapper src={OliverWestTower} alt="Oliver West Tower" />

        <MarkdownSection
          options={{
            parent: "Home",
            imagePositionRight: homeData?.Madison?.ImagePosition === "Right",
            title: `# THE OLIVER - **YOUR BEST MOVE**`,
            body: `The Oliver is a prominent inner-city development defined by exceptional quality and the convenience and simplicity of a maintenance-free lifestyle. Oliver one and two-bedroom suites feature stunning views and cutting-edge finishing details.

The Oliver is a place where you can enjoy the best, and where you can be your best. From entertaining your expanded social network to indoor get-togethers with close friends, The Oliver has everything in place to elevate you and your lifestyle.
        
The Oliver features an abundance of outdoor space including dog-friendly areas, seating, fire spaces, community garden, recreation, fitness centre, dining and social BBQ areas. The interior amenities will provide additional community gathering space, kitchen, lounge, games room, entertainment, and pet wash uses for residents. Resident lobbies will include a main floor lounge, parcel storage, and concierge for resident convenience and accessibility.

[LEARN MORE](https://oliveron10th.com/amenities)`,
            color: convertColor(homeData?.Madison?.Color),
            textColor: "rgba(255,255,255,1)",
            titleColor: "rgba(254, 200, 87, 1)",
            cols: isSmall || isXsmall ? 12 : 8,
          }}
          // lazy={false}
        />
      </Grid>
      <MapSection />
      <Footer />
    </Container>
  );
}

const FullWidthImageWrapper = styled("img")`
  max-width: 1190px;
  height: auto;
  width: 100dvw;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: calc(-8rem + 100dvw);
  }
`;
export default App;
