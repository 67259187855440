import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";
import { responsiveFontSizes } from "@mui/material/styles";

const font = "'Roboto', sans-serif";
const titleFont = font;

const defaultTheme = responsiveFontSizes(
  createMuiTheme({
    maxWidth: "1920px",
    activeColor: "#6098AF",
    palette: {
      primary: {
        main: "rgba(44,54,53,1)", //Dark Grey
        dark: "rgba(4, 62, 95, 1)",
        red: "rgba(168, 53, 58, 1)",
        taupe: "rgba(179, 175, 165, 1)",
        navy: "rgba(41,76,96,1)",
        yellow: "rgba(254,200,87,1)",
        teal: "rgba(34,87,85,1)",
        slate: "rgba(112,125,132,1)",
        light: "rgba(192, 202, 200, 1)",
        // active: "rgba(192, 202, 200, 0.54)", //Toggled
        hover: "rgba(168, 53, 58, 1)",
        selected: "rgba(192, 202, 200, 0.54)",
        contrastText: "#fff",
        footer: "rgb(3,44,67)",
        bullet: "rgba(192, 202, 200,1)",
      },
      secondary: {
        dark: "rgba(112,125,132,1)", //slate
        main: "#466566", //L Green
        light: "rgba(245, 245, 245,1)", // light grey
        highlight: "#E6781F", //Orange
      },
      common: {
        lightBlack: "#1b1c1e",
        backgroundGrey: "rgba(245,245,245,1)",
      },
      action: {
        //This is a reference. You can use theme.palette.action.active etc.
        active: "rgba(192, 202, 200, 0.54)",
        hover: "rgba(37,95,126,1)",
        hoverOpacity: 0.1,
        selected: "rgba(192, 202, 200, 0.54)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
      text: {
        // primary: "rgba(112, 125, 132, 1)",
        primary: "rgba(42, 48, 46, 1)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
    typography: {
      htmlFontSize: 17,
      fontFamily: font,
    },
  })
);

export const theme = responsiveFontSizes(
  createMuiTheme({
    ...defaultTheme,
    typography: {
      h1: {
        color: defaultTheme.palette.text.primary,
        fontSize: "1.6rem",
        fontFamily: titleFont,
        fontWeight: 400,
        textTransform: "uppercase",
        [defaultTheme.breakpoints.up("sm")]: {
          fontSize: "2.0rem",
        },
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: "2rem",
        },
        [defaultTheme.breakpoints.up("lg")]: {
          fontSize: "2rem",
        },
        [defaultTheme.breakpoints.up("xl")]: {
          fontSize: "2rem",
        },
      },

      h2: {
        fontSize: "1rem",
        fontFamily: titleFont,
        fontWeight: "normal",
        textTransform: "uppercase",
        [defaultTheme.breakpoints.up("sm")]: {
          fontSize: "1.4rem",
        },
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: "1.4rem",
        },
        [defaultTheme.breakpoints.up("lg")]: {
          fontSize: "1.4rem",
        },
        [defaultTheme.breakpoints.up("xl")]: {
          fontSize: "1.4rem",
        },
      },
      h3: {
        // whiteSpace: "nowrap",
        fontSize: "1.3rem",
        textTransform: "uppercase",
        fontFamily: titleFont,
        fontWeight: 700,
        [defaultTheme.breakpoints.up("sm")]: {
          fontSize: "1.5rem",
        },
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: "1.8rem",
        },
        [defaultTheme.breakpoints.up("lg")]: {
          fontSize: "2.0rem",
        },
      },
      h4: {
        fontSize: "1.3rem",
        fontFamily: titleFont,
        fontWeight: 700,
        textTransform: "uppercase",
      },
      h5: {
        // whiteSpace: "nowrap",
        fontSize: "1.4107142857142856rem",
        textTransform: "uppercase",
        fontFamily: titleFont,
        fontWeight: 700,
        [defaultTheme.breakpoints.up("sm")]: {
          fontSize: "1.2rem",
        },
        [defaultTheme.breakpoints.up("md")]: {
          fontSize: "1.6867rem",
        },
        [defaultTheme.breakpoints.up("lg")]: {
          fontSize: "1.4rem",
        },
      },
      h6: {
        fontFamily: titleFont,
        fontWeight: 700,
        // whiteSpace: "nowrap",
        // textTransform: "uppercase",
      },
      body1: {
        color: defaultTheme.palette.primary.main,
        maxWidth: defaultTheme.breakpoints.values.lg,
        lineHeight: "1.7rem",
        fontSize: "0.9411764705882353rem",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    components: {
      // Style sheet name

      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: defaultTheme.palette.primary.slate,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: defaultTheme.palette.primary.main,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: defaultTheme.palette.common.white,
            "&:focus": { backgroundColor: "transparent" },
          },

          button: {
            "&:hover": { backgroundColor: defaultTheme.palette.primary.slate, color: "white" },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          color: "rgba(255,255,255,1)",
          root: {
            // color: "rgba(255,255,255,1)",
            "&.MuiTab-root .MuiTypography-body1": { color: "rgba(206,206,206,1)" },
            "&.Mui-selected": {
              backgroundColor: "#f5f5f5",

              "&.Mui-selected .MuiTypography-body1": {
                color: defaultTheme.palette.primary.slate,
              },
            },
            "&:hover": {
              backgroundColor: defaultTheme.palette.primary.light,
              ".MuiTypography-body1": {
                color: defaultTheme.palette.primary.dark, // Example: Customize the color on hover
                opacity: 1,
              },
            },
            textColorInherit: {
              color: defaultTheme.palette.primary.red,
              opacity: 1,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: { colorSecondary: { backgroundColor: defaultTheme.palette.primary.slate } },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the rule
          contained: {
            color: defaultTheme.palette.common.white,
            backgroundColor: defaultTheme.palette.primary.slate,
            "&:hover": {
              backgroundColor: "rgba(213,213,213,1)",
              color: defaultTheme.palette.primary.slate,
            },
          },
          root: {
            borderRadius: 21,
            height: 42,
          },
          label: {
            // Some CSS
            fontSize: ".8rem",
            fontStyle: "italic",
          },
        },
      },

      MuiCardActions: {
        styleOverrides: {
          root: {
            backgroundColor: defaultTheme.palette.primary.slate,
            color: defaultTheme.palette.common.white,
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "1rem",
            fontFamily: "Helvetica",
          },

          inputMultiline: {
            lineHeight: "1.5rem",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: "1.2rem",

            // color: "red",
          },
        },
      },
    },
  })
);
