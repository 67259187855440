import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Dialog, Typography } from "@mui/material";
function ZoomedFloorplan({
  showLabel = false,
  open,
  setOpen,
  useBackground,
  image,
  width,
  imageAlt,
  unitName,
  ...props
}) {
  function handleBackdropClick(event) {
    setOpen(false);
  }
  return (
    <>
      <Wrapper
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClick={handleBackdropClick}
        onClose={handleBackdropClick}
        useBackground={useBackground}
        onKeyPress={event => {
          if (event.key === "Enter") {
            handleBackdropClick();
          }
        }}
      >
        <div style={{ position: "absolute", right: "2rem", top: "2rem" }}>[X] Close</div>

        {showLabel && (
          <div>
            <Typography align="center" variant="h1" paragraph>
              {imageAlt}
            </Typography>
          </div>
        )}
        <BigImage
          src={image}
          useBackground={useBackground}
          alt={imageAlt}
          onClick={handleBackdropClick}
          loading="lazy"
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Dialog)`
  // position: relative;
  .MuiDialog-paperScrollPaper {
    background: unset;
    background-color: ${props =>
      props.useBackground ? props.theme.palette.common.backgroundGrey : null};
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 100%;
    width: 100%;
    box-shadow: none;
    margin: 0;

    max-height: calc(100% - 12px);
  }
`;

const BigImage = styled("div")`
  /* background: url(${props => props.src}) center/90% no-repeat; */
  background-color: ${props => (props.useBackground ? props.theme.palette.common.white : null)};
  width: 95%;
  height: 78%;

  ${props => props.theme.breakpoints.down("md")} and (orientation: portrait) {
    background: url(${props => props.src}) center/contain no-repeat;
  }
  ${props => props.theme.breakpoints.down("md")} and (orientation: landscape) {
    background: url(${props => props.src}) center/ auto 100% no-repeat;
  }

  /* ${props => props.theme.breakpoints.up("md")} and (orientation: portrait) {
    background: url(${props => props.src}) center/auto 90% no-repeat;
  } */
  ${props => props.theme.breakpoints.up("md")} {
    background: url(${props => props.src}) center/contain no-repeat;
  }
  &:hover {
    cursor: zoom-out;
  }
  & > h2 {
    margin-top: 2rem;
  }
`;

ZoomedFloorplan.propTypes = {
  showLabel: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  useBackground: PropTypes.bool,
  image: PropTypes.string,
  width: PropTypes.string,
  imageAlt: PropTypes.string,
  unitName: PropTypes.string,
};

export default ZoomedFloorplan;
