import React from "react";
import styled from "@emotion/styled";

function Video() {
  return (
    <Wrapper>
      <div
        className="wistia_responsive_padding"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
            maxWidth: "1190px",
          }}
        >
          <div
            className="wistia_embed wistia_async_1h3pkydonf videoFoam=true allowtransparency=true"
            style={{ height: "100%", position: "relative", width: "100%" }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  width: 100%;
  max-width: 1190px;
  margin-top: 3rem;
`;
export default Video;
