import React, { useState } from "react";

import styled from "@emotion/styled";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomedImage from "./ZoomedImage";

/**
 * generates a clickable image
 *  Should receive one image from Strapi with different formats depending on size
 *
 * @param {*} { data, isTouch, ...props }
 * @returns react component
 */
function ClickableImage({ data, isTouch, ...props }) {
  // const imageToUseHack = data;
  // TODO replace imageToUseHack with data?.Media[0] when not hacking
  const [zoomedImage, setZoomedImage] = useState({ url: "", width: 0 });
  const [altImage, setAltImage] = useState();
  const [open, setOpen] = useState(false);

  const image = {};

  if (data?.hasOwnProperty("Media") && data?.Media.length > 1) {
    image.url = data.Media[1].formats.small.url;

    image.srcSet = Object.keys(data?.Media[1]?.formats)
      .map((key, index) => {
        return `${data?.Media[1]?.formats[key].url} ${data?.Media[1]?.formats[key].width}w `;
      })

      .join(",");
    image.srcSet += `, ${data?.Media[1]?.url} ${data?.Media[1]?.width}w`;
    image.alt = data?.Media[1].alternativeText;
  }

  if (data?.hasOwnProperty("Media") && data?.Media.length === 1) {
    image.url = data?.Media[0]?.formats?.small?.url;

    image.srcSet = Object.keys(data?.Media[0]?.formats)
      .map((key, index) => {
        return `${data?.Media[0]?.formats[key]?.url} ${data?.Media[0]?.formats[key]?.width}w `;
      })
      .join(",");

    image.srcSet += `, ${data?.Media[0]?.url} ${data?.Media[0]?.width}w`;
    image.alt = data?.Media[0].alternativeText;
    image.largeImage = {
      url: data?.Media[0].url,
      alternativeText: data?.Media[0].alternativeText,
      width: data?.Media[0]?.width,
    };
  }

  const handleOnClick = (largeimage, e) => {
    e.preventDefault();
    if (!largeimage || !largeimage.url) {
      return;
    }
    setZoomedImage({
      url: largeimage?.url,
      width: largeimage?.width,
      aspect: data?.Media[0]?.width / data?.Media[0]?.height,
    });
    // setImage(e.currentTarget.attributes.largeimage.value);
    setAltImage(
      largeimage?.alternativeText !== "" ? largeimage?.alternativeText : largeimage?.name
    );
    setOpen(true);
  };

  return (
    <Wrapper {...props}>
      {isTouch && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            color: "white",
            padding: ".5rem",
          }}
        >
          <ZoomInIcon fontSize="large" />
        </div>
      )}
      {/* TODO abstract the sizes so it can be passed as prop */}
      <Image
        // srcSet={`${data?.Media[0]?.formats["small"].url} ${data?.Media[0]?.formats["small"].width}w`}
        srcSet={image.srcSet}
        sizes="(max-width: 390px) 100vw, (max-width: 600px) 552px, (max-width: 960px) 912px, (max-width: 1280px) 1232px, 100vw"
        // sizes="(min-width: 1280px) 1232px, 375px"
        src={image.url}
        alt={image.alternativeText}
        // largeimage={LargeImageData}
        loading="lazy"
        onClick={e => handleOnClick(image.largeImage, e)}
        role="presentation"
      />

      {open && (
        <ZoomedImage
          open={open}
          setOpen={setOpen}
          image={zoomedImage.url}
          width={zoomedImage.width}
          aspect={zoomedImage.aspect}
          imageAlt={altImage}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  width: 100vw;
  height: auto;
  position: relative;
  max-width: ${props => props?.maxwidth};
  max-height: ${props => props?.maxheight};
`;
const Image = styled("img")`
  &:hover {
    ${props => (props?.largeimage?.url ? "cursor: zoom-in" : null)}
  }
`;
ClickableImage.propTypes = {};

export default ClickableImage;
