import React from "react";
import styled from "@emotion/styled";

import { Event } from "./Tracking";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";

const handleOnClick = e => {
  // console.log(e.currentTarget.text);
  Event("Social", "click", e.currentTarget.dataset.name);
};

function SocialIcons(props) {
  return (
    <StyledSocial className={props.className} style={props.style}>
      {props.children}
      {props.youtube && (
        <a
          onClick={handleOnClick}
          data-name="YouTube"
          href={props.youtube}
          target={props.target}
          tabIndex="-1"
        >
          <YouTubeIcon size={props.size} />
        </a>
      )}
      {props.pinterest && (
        <a
          onClick={handleOnClick}
          data-name="Pinterest"
          href={props.pinterest}
          target={props.target}
          tabIndex="-1"
        >
          <PinterestIcon size={props.size} alt="Visit us on Pinterest" />
        </a>
      )}
      {props.facebook && (
        <a
          onClick={handleOnClick}
          data-name="Facebook"
          href={props.facebook}
          target={props.target}
          tabIndex="-1"
        >
          <StyledFacebookIcon size={props.size} alt="Visit us on Facebook" />
        </a>
      )}
      {props.twitter && (
        <a
          onClick={handleOnClick}
          data-name="Twitter"
          href={props.twitter}
          target={props.target}
          tabIndex="-1"
        >
          <StyledTwitterIcon size={props.size} alt="Visit us on Twitter" />
        </a>
      )}
      {props.instagram && (
        <a
          onClick={handleOnClick}
          data-name="Instagram"
          href={props.instagram}
          target={props.target}
          tabIndex="-1"
        >
          <StyledInstagramIcon size={props.size} alt="Visit us on Instagram" />
        </a>
      )}
    </StyledSocial>
  );
}
const StyledSocial = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 4em;

  a:first-child {
    margin-left: 0;
  }
  a {
    margin-left: 1em;
  }
  ${props => props.theme.breakpoints.up("sm")} {
    width: auto;
    display: flex;
    height: auto;
    padding-top: 1rem;

    a {
      background: transparent;
      &:hover {
        background: transparent;
        svg > path {
          fill: ${props => props.theme.activeColor};
        }
      }
    }

  p {
    text-align: center;
  }
`;
const StyledFacebookIcon = styled(FacebookIcon)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => (props.size === undefined ? "1.5em" : props.size)} !important;
  ${props => props.theme.breakpoints.up("sm")} {
    color: ${props => props.theme.palette.common.white};
  }
`;
const StyledTwitterIcon = styled(TwitterIcon)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => (props.size === undefined ? "1.5em" : props.size)} !important;
  ${props => props.theme.breakpoints.up("sm")} {
    color: ${props => props.theme.palette.common.white};
  }
`;
const StyledInstagramIcon = styled(InstagramIcon)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => (props.size === undefined ? "1.5em" : props.size)} !important;
  ${props => props.theme.breakpoints.up("sm")} {
    color: ${props => props.theme.palette.common.white};
  }
`;
const StyledYoutubeIcon = styled(YouTubeIcon)`
  color: ${props => props.theme.palette.common.white};
  font-size: ${props => (props.size === undefined ? "1.5em" : props.size)} !important;
  ${props => props.theme.breakpoints.up("sm")} {
    color: ${props => props.theme.palette.common.white};
  }
`;

export default SocialIcons;
